
const toggleSearch = (e) => {
    document.querySelector(".search").classList.toggle("open");
    document.querySelector(".search").children[1].focus();
}

window.addEventListener('load', function () {
    if (document.querySelector(".search")) {
        document.querySelector(".search").addEventListener('click', (event) => {
            toggleSearch(event);
        });
    }
})